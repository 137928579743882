/* width */
::-webkit-scrollbar {
	width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.75);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(255, 255, 255, 0.15);
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	background-color: black;
}

.basicLightbox {
    background: rgba(0,0,0,0.95);
}